<template>

	<div class="customer-service-page-container">
<!--		<LoginModal v-if="isLogin === false" :canShowLogo="false" @onLoginSuccess="this.onLoginSuccess"-->
<!--		            :loginUrl="'customerServicePanel/login'"/>-->

		<div class="cs-view-switch-container">
			<div class="cs-view-switch-wrapper">
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'vpn'}" @click="this.onViewSwitcherClicked('vpn')">类目一</div>
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'airport'}" @click="this.onViewSwitcherClicked('airport')">类目二</div>
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'healthcheck'}" @click="this.onViewSwitcherClicked('healthcheck')">健康检查</div>
			</div>

		</div>
		<AirportStatisticView v-if="this.currentViewSwitcher === 'airport'"/>
		<VpnStatisticView v-if="this.currentViewSwitcher === 'vpn'"/>
		<HealthCheckView v-if="this.currentViewSwitcher === 'healthcheck'"/>
	</div>

</template>

<script>
import {
	getRequestAsync,
	hideLoading,
	showLoading
} from "@/utils/methods";
import LoginModal from "../../components/LoginModal.vue";
import AirportStatisticView from "@/pages/statistic/airport/AirportStatisticView.vue";
import VpnStatisticView from "@/pages/statistic/vpn/VpnStatisticView.vue";
import HealthCheckView from "@/pages/statistic/vpn/HealthCheckView.vue";

export default {
	name: "StatisticPage",
	components: {
		AirportStatisticView,
		VpnStatisticView,
		HealthCheckView
	},
	data() {
		return {
			mainTabKey: 'general',
			currentViewSwitcher: 'vpn'
		}
	},
	async beforeMount() {
		// this.isLogin = await this.checkLogin()
		if (this.isLogin) {
			// await this.getMarketingInfo()
		}
	},
	methods: {
		onViewSwitcherClicked(viewType) {
			this.currentViewSwitcher = viewType
		},
		async checkLogin() {
			showLoading()
			const result = await getRequestAsync('customerServicePanel/verifySession')
			hideLoading()
			if (result.code !== 200) {
				localStorage.removeItem('sessionId')
				localStorage.removeItem('lastVerify')
			}
			return result.code === 200
		},
		onLoginSuccess() {
			this.isLogin = true
		}
	}
}
</script>

<style scoped>

.customer-service-page-container {
	padding: 1em;
}

.customer-service-page-container {
	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;
}

.cs-menu-container {
	top: 0;
	left: 0;
	flex: 1;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 1em 0.8em;
	overflow-y: scroll;
}


.topic-menu-item {
	display: flex;
	flex-direction: column;
	padding: 0.6em;
	border-bottom: 1px solid #E6D5B8;
	align-items: start;
}

.cs-view-switch-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.cs-view-switch-wrapper {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.cs-view-switch-btn {
	color: #FF8900;
	border: 1px solid #FF8900;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	margin: 0 0.4em;
}

.cs-view-switch-btn.active {
	color: #000000;
	background-color: #FF8900;
}


.cs-main-container {
	display: flex;
	flex-direction: row;

	height: 100vh;
	width: 100vw;
	overflow-y: hidden;
}

.cs-topic-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	color: #E6D5B8;
}

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container {
	display: table;
	align-items: start;
	color: #E6D5B8;
	padding-bottom: 0.2em;
}

.statistic-visit-sub-container-row {
	display: table-row;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container-cell {
	display: table-cell;
	align-items: start;
	color: #E6D5B8;
	text-align: start;
	padding-right: 0.8em;
}

.stat-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.stat-cell-title {
	padding-top: 0.4em;
	color: var(--white-color);
}

.stat-content {
	font-size: 1.4em;
	color: var(--white-color);
}

@media only screen and (max-width: 900px) {
	.statistic-info-container {
		grid-template-columns: 1fr 1fr;
	}
}


</style>