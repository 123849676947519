import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from './pages/LoginPage.vue';
import DashboardPage from './pages/DashboardPage.vue';
import CustomerServicePage from './pages/CustomerServicePage.vue';
import HealthCheckPage from './pages/HealthCheckPage.vue';
import StatisticPage from './pages/statistic/StatisticPage.vue';
import ChartPage from './pages/ChartPage.vue';
import ReportPage from './pages/ReportPage.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: '/', component: LoginPage, name: 'login'},
		{path: '/login', component: LoginPage, name: 'login'},
		{
			path: '/dashboard',
			component: DashboardPage,
			name: 'dashboard',
			redirect: '/dashboard/hc',
			children: [
				{
					path: 'cs',
					name: 'cs',
					component: CustomerServicePage
				},
				{
					path: 'hc',
					name: 'hc',
					component: HealthCheckPage
				},
				{
					path: 'stats',
					name: 'stats',
					component: StatisticPage
				},
				{
					path: 'chart',
					name: 'chart',
					component: ChartPage
				},
				{
					path: 'report',
					name: 'report',
					component: ReportPage
				}
			]
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/dashboard' // 重定向到默认路径
		}
	]
});

export default router;