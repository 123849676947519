<template>
	<!--	<div class="sl-confirm-modal-bg-container"event.stopPropagation()">-->
	<div class='image-display-modal-bg'>
		<img src="../images/close_topic.svg" alt="close" class="close-image-btn" @click="closeImage">
		<img :src=this.imgUrl alt="image" class="image-display-img">
	</div>
	<!--	</div>-->


</template>

<script>
import {postRequest, verifyEmail} from '@/utils/methods'

export default {
	name: "ImageDisplayModal",
	data() {
		return {

		}
	},
	props: {
		imgUrl: {
			type: String,
			required: true
		}
	},
	methods: {
		onCancel() {
			this.$emit('cancel')
		},
		onConfirm() {
			this.$emit('confirm')
		},
		onBgClick(event) {
			event.stopPropagation()
		},
		closeImage() {
			this.$emit('closeImage')
		}
	}
}
</script>

<style scoped>

.image-display-modal-bg {
	position: fixed;
	width: 100vw;
	z-index: 1001;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	left: 0;
	height: 100vh;
	backdrop-filter: blur(3px);
}

.image-display-img {
	max-height: 90%;
	max-width: 90%;
	position:fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.close-image-btn{
	width: 1.6em;
	position:fixed;
	top: 0;
	right: 0;
	margin: 1em;
	cursor: pointer;
	z-index: 1002;
}
</style>