<template>
	<div class="main-container">
		<MainMenu :isMenuOpen="this.isMenuOpen" @menuItemClicked="onMenuItemClicked" @showMenu="onShowMenu" @hideMenu="onHideMenu"></MainMenu>
		<router-view></router-view>
	</div>
</template>
<script>

import MainMenu from "../components/MainMenu.vue";
export default {
	name: "DashboardPage",
	components: {
		MainMenu
	},
	data() {
		return {
			isMenuOpen: false
		}
	},
	async mounted() {
	},
	methods: {
		onMenuItemClicked () {
			this.isMenuOpen= false
		},
		onShowMenu() {
			this.isMenuOpen= true
		},
		onHideMenu() {
			this.isMenuOpen= false
		}
	}
}
</script>
<style scoped>
.main-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	position: relative;
}
</style>