<template>
	<ImageDisplayModal v-if="this.displayImgUrl !== null" :img-url="this.displayImgUrl" @closeImage="onCloseImage"></ImageDisplayModal>
	<div class="general-modal-bg">
		<div class='general-modal-container'>
			<div class='general-modal-header-container'>
				<div>工单详情</div>
				<img class="general-modal-header-close-btn" src="../images/close_white.svg" alt="close"
				     @click="this.onClose">
			</div>
			<div class="topic-detail-container">
				<div class='topic-title'>标题：{{ this.ticketItem?.userDescription }}</div>
				<div class='topic-type'>工单类型
					<img v-if="this.viewTicketType === false" src="../images/view_close.svg" alt="view"
					     @click="onViewTicketType(true)" style="cursor: pointer;margin-left: 0.2em;">
					<img v-if="this.viewTicketType === true" src="../images/view_open.svg" alt="view"
					     @click="onViewTicketType(false)" style="cursor: pointer;margin-left: 0.2em;">
				</div>
				<div class="topic-type-container" v-if="this.viewTicketType===true">
					<div v-for="(item) in this.ticketItem?.topicType" :key="item.key" class='topic-type'>
						<span class="topic-type-label">{{ item.label }}</span>
					</div>
				</div>

				<div class='topic-email'>邮箱：{{ this.ticketItem?.email }}</div>
				<div class='topic-email'>创建时间：{{ this.ticketItem?.createdTimeString }}</div>
				<div class='topic-email'>更新时间：{{ this.ticketItem?.updatedTimeString }}</div>
				<div class='user-detail'>用户详情
					<img v-if="this.viewUserDetail === false" src="../images/view_close.svg" alt="view"
					     @click="onViewDetail(true)" style="cursor: pointer;margin-left: 0.2em;">
					<img v-if="this.viewUserDetail === true" src="../images/view_open.svg" alt="view"
					     @click="onViewDetail(false)" style="cursor: pointer;margin-left: 0.2em;">
				</div>
				<div class='user-detail-container' v-if="this.viewUserDetail === true">
					<div>注册时间：{{ this.formatTimeString(this.userDetail?.registerTime) }}</div>
					<div>上次登录：{{ this.formatTimeString(this.userDetail?.lastLoginTime) }}</div>
					<div>会员到期：{{ this.formatTimeString(this.userDetail?.planExpireTime) }}</div>
					<div>支付数量：{{ this.userDetail?.paymentCount }}</div>
					<div>推荐数量：{{ this.userDetail?.referCount }}</div>
					<div>推荐人：{{ this.userDetail?.referByEmail || '无' }}</div>
					<div>推荐码：{{ this.userDetail?.referByCode || '无' }}</div>
				</div>

				<div class='ticket-img' v-if="this.ticketItem.images?.length > 0">图片</div>
				<div class="ticket-img-container" v-if="this.ticketItem.images?.length > 0">
					<div v-for="(item) in this.ticketItem?.images" class="ticket-img-wrapper" :key="item" @click="onImageClicked(item)">
						<img :src=item alt="image" style="max-width: 100%; max-height: 100%;"></div>
				</div>
				<div class='device-info-label'>设备信息
					<img v-if="this.viewDeviceInfo === false" src="../images/view_close.svg" alt="view"
					     @click="onViewDeviceInfo(true)" style="cursor: pointer;margin-left: 0.2em;">
					<img v-if="this.viewDeviceInfo === true" src="../images/view_open.svg" alt="view"
					     @click="onViewDeviceInfo(false)" style="cursor: pointer;margin-left: 0.2em;">
				</div>
				<div class="device-info-container" v-if="this.viewDeviceInfo === true">
					<div class="flex-table">
						<div class="table-header">
							<div class="table-cell">系统</div>
							<div class="table-cell">版本</div>
							<div class="table-cell">更新时间</div>
							<div class="table-cell">创建时间</div>
						</div>
						<template v-for="item in this.deviceInfo" :key="item.deviceId">
							<!--							<div class="device-info-row">{{item.os + ' v' + item.version}}</div>-->
							<div class="table-row" v-if="item.os">
								<div class="table-cell">{{ item.os }}</div>
								<div class="table-cell">{{ item.version }}</div>
								<div class="table-cell">{{ item.updateTimeString }}</div>
								<div class="table-cell">{{ item.createTimeString }}</div>
							</div>
						</template>
					</div>

				</div>
				<div class='topic-conversation'>对话：<br></div>
				<template v-for="(item) in this.conversationList" :key="item.conversationId">
					<div class="cs-chat-content-wrapper">
						<div
							:class="[item.isClientChat === true ? 'cs-topic-detail-client-chat' : 'cs-topic-detail-cs-chat']"
							style="width: 100%;text-align: start;">
							<div class="chat-box-header">
								<div class="chat-box-talker-container">
									{{ (item.isClientChat === true ? '用户：\n' : '我：\n') }}
								</div>
								<img src="../images/delete_chat.svg" alt="close topic" v-if="item.isClientChat !== true"
								     style="width: 1.2em;cursor: pointer;">
							</div>
							<div
								:class="[item.isClientChat === true ? 'chat-box-client-msg-container' : 'chat-box-my-msg-container']">
								{{ item.chatContent }}
							</div>

							<div style="width: 100%;text-align: right;">{{ item.createdTimeString }}</div>
						</div>
					</div>
				</template>
			</div>

			<div class="editor-container">
				<textarea class='cs-input' type="text" placeholder='请在此输入信息' rows="3" v-model="this.toSendMsg"/>
				<div class="editor-btn-container">
					<a-button type="primary" size="middle" @click="sendMsgReply"
					          style="color: #191123FF;background-color: #FF8900;margin-left: 0.2em;margin-bottom: 0.2em;">
						发送
					</a-button>
					<a-button type="primary" size="middle" @click="sendMsgReply"
					          style="color: #191123FF;background-color: #FF8900;margin-left: 0.2em;">
						<img src="../images/attachment.svg" alt="attachment">
					</a-button>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
import {hideLoading, postRequestAsync, showLoading} from "@/utils/methods";
import moment from "moment-timezone";
import ImageDisplayModal from "../components/ImageDisplayModal.vue";

export default {
	name: "TopicDetailModal",
	components: {ImageDisplayModal},
	data() {
		return {
			conversationList: [],
			viewUserDetail: false,
			userDetail: null,
			toSendMsg: '',
			viewTicketType: false,
			viewDeviceInfo: false,
			deviceInfo: [],
			displayImgUrl: null
		}
	},
	props: {
		ticketItem: {
			type: Object,
			required: true,
			toSendMsg: ''
		}
	},
	async mounted() {
		await this.getChat()
	},
	methods: {
		onCloseImage() {
			this.displayImgUrl = null
		},
		onClose() {
			this.$emit('close');
		},
		async sendMsgReply() {
			if (!this.toSendMsg) {
				this.$message.error('对话信息不能为空')
				return
			}
			showLoading()
			const result = await postRequestAsync('admin/cs/sendTopicReply', {
				topicId: this.ticketItem?.feedbackTopicId,
				msg: this.toSendMsg
			})
			hideLoading()

			if (result.code === 200) {
				this.$message.success('发送成功');
				this.conversationList = result?.msg
				this.toSendMsg = ''
				this.$emit('ticketUpdated')
			}
		},
		async onViewDetail(shouldView) {
			this.viewUserDetail = shouldView
			if (shouldView === true && !this.userDetail) {
				await this.getUserDetail()
			}
		},
		onViewTicketType(view) {
			this.viewTicketType = view
		},
		formatTimeString(timestamp) {
			if (!timestamp) {
				return '无'
			}
			return moment(parseInt(timestamp) * 1000).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
		},
		async getUserDetail() {
			if (!this.ticketItem?.email) {
				return
			}
			showLoading()
			try {
				const result = await postRequestAsync('admin/cs/getVpnUserDetail', {
					email: this.ticketItem?.email
				})
				if (result?.code === 200) {
					this.userDetail = result?.msg
				}
			} finally {
				hideLoading()
			}
		},
		async getChat() {
			if (!this.ticketItem?.feedbackTopicId) {
				return
			}
			showLoading()
			try {
				const result = await postRequestAsync('admin/cs/getVpnTopicChat', {
					ticketId: this.ticketItem?.feedbackTopicId
				})
				if (result?.code === 200) {
					this.conversationList = result?.msg
				}
			} finally {
				hideLoading()
			}
		},
		async getDeviceInfo() {
			showLoading()
			const result = await postRequestAsync('admin/cs/getDeviceInfo', {
				ticketId: this.ticketItem?.feedbackTopicId
			})
			hideLoading()

			if (result.code === 200) {
				this.deviceInfo = result?.msg
			}
		},
		async onViewDeviceInfo(view) {
			if (view === true && this.deviceInfo.length === 0) {
				await this.getDeviceInfo()
			}
			this.viewDeviceInfo = view
		},
		onImageClicked(url) {
			this.displayImgUrl = url
		}
	}
}
</script>
<style scoped>
.general-modal-container {
	position: relative;
	background-color: #1f1f35;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	padding: 0.4em;
}

.topic-title {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	word-wrap: break-word;
}

.topic-type {
	width: fit-content;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	word-wrap: break-word;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.topic-email {
	width: 100%;
	font-size: 1.2em;
	color: rgba(230, 213, 184, 0.8);
	text-align: start;
}

.user-detail {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	display: flex;
	flex-direction: row;
}

.user-detail-container {
	display: flex;
	font-size: 1.2em;
	line-height: 1.2em;
	flex-direction: column;
	width: 100%;
	color: #E6D5B8;
	align-items: start;
	background-color: rgba(230, 213, 184, 0.2);
	padding: 0.2em;
	box-sizing: border-box;
	border-radius: 0.2em;
}

.topic-conversation {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
}

.cs-topic-detail-client-chat {
	background-color: rgba(255, 137, 0, 0.2);
	color: #FF8900;
	padding: 0.4em;
	border-radius: 0.4em;
	margin: 0.4em 0;
	width: fit-content;
	box-sizing: border-box;
}

.cs-topic-detail-cs-chat {
	background-color: rgba(230, 213, 184, 0.2);
	color: #E6D5B8;
	padding: 0.4em;
	border-radius: 0.4em;
	margin: 0.4em 0;
	width: fit-content;
	box-sizing: border-box;
}

.editor-container {
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	padding: 0.2em 0.2em 2em 0.2em;
	align-items: flex-start;
	background-color: #1f1f35;
}

textarea {

	background-color: transparent;
	border: 1px solid #FF8900;
	border-radius: 0.2em;
	flex: 1;
	color: #FF8900;
	font-size: 1.2em;
	min-height: calc(3 * 1.4em); /* 最少3行 */
	max-height: calc(10 * 1.4em); /* 最多10行 */
	line-height: 1.4em;
	overflow-y: auto;
	resize: vertical;
	padding: 5px;
	box-sizing: border-box;
}

textarea:focus {
	border: 1px solid #FF8900;
}

textarea:focus-visible {
	outline: #ffad50 auto 1px;
}

/* 其他浏览器 */
textarea::placeholder {
	color: #c76c00;
}

.editor-btn-container {
	display: flex;
	flex-direction: column;
}

.topic-detail-container {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	flex: 1;
}

.chat-box-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.chat-box-client-msg-container {
	color: #FF8900;
	word-wrap: break-word;
}

.chat-box-my-msg-container {
	color: #E6D5B8;
	word-wrap: break-word;
}

.topic-type-container {
	display: flex;
	flex-direction: column;
	border: 1px solid #E6D5B8;
	padding: 0.2em;
	border-radius: 0.2em;
}

.topic-type-label {
	word-break: keep-all;
	white-space: nowrap;
	color: rgba(230, 213, 184, 0.8);
	line-height: 1.2em;
}

.ticket-img {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	display: flex;
	flex-direction: row;
}

.device-info-label {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	display: flex;
	flex-direction: row;
}

.ticket-img-container {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.ticket-img-wrapper {
	width: 5em;
	height: 5em;
	background-color: #000;
	padding: 0.2em;
	box-sizing: border-box;
}

.device-info-container {
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(230, 213, 184, 0.5);
	padding: 0.2em;
	border-radius: 0.2em;
	margin: 0.4em 0;
	box-sizing: border-box;
}

.device-info-row {
	display: flex;
	flex-direction: row;
	color: #E6D5B8;
}

.flex-table {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	font-family: Arial, sans-serif;
}

.table-header, .table-row {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #E6D5B8;
	width: 100%;
	min-width: 100%;
	box-sizing: border-box;
}

.table-header {
	background-color: rgba(230, 213, 184, 0.3);
	font-weight: bold;
}

.table-cell {
	padding: 0.2em;
	text-align: center;
	/* 关键：添加这些属性确保内容不会撑开单元格 */
	box-sizing: border-box;
	min-width: 0; /* 防止flex项目超出容器 */
	word-wrap: break-word; /* 长文本自动换行 */
	color: #E6D5B8;
}

.table-cell:nth-child(1),
.table-cell:nth-child(2) {
	flex: 0 0 auto; /* 不伸缩，保持内容宽度 */
	white-space: nowrap; /* 防止文本换行 */
}

.table-cell:nth-child(3),
.table-cell:nth-child(4) {
	flex: 1 1 0; /* 可伸缩，平分剩余空间 */
}


</style>