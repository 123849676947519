<template>
	<!--	<div class="sl-confirm-modal-bg-container"event.stopPropagation()">-->
	<div class='sl-confirm-modal-bg' @click="onBgClick($event)">
		<div class='sl-confirm-modal'>
			<div class='sl-confirm-modal-container'>
				<div class='sl-confirm-modal-title'>{{ this.confirmMsg }}</div>

				<div class='sl-confirm-modal-btn-container'>
					<a-button type="primary" size="middle" @click="onCancel" style="color: #E6D5B8 !important;background-color: #1f1f35 !important;margin-right: 0.5em;border: 1px solid #E6D5B8;">
						{{ this.cancelBtnText}}
					</a-button>
					<a-button type="primary" size="middle" @click="onConfirm" style="color: #1f1f35 !important;background-color: #E6D5B8 !important;margin-right: 0.5em;">
						{{ this.confirmBtnText}}
					</a-button>
				</div>
			</div>
		</div>
	</div>
	<!--	</div>-->


</template>

<script>
import {postRequest, verifyEmail} from '@/utils/methods'

export default {
	name: "ConfirmModal",
	data() {
		return {
			email: '',
			vcode: '',
			password: '',
			confirmPassword: '',
			sendEmailCountDown: -1,
			countDownInterval: null
		}
	},
	props: {
		confirmMsg: {
			type: String,
			required: true,
		},
		confirmBtnText: {
			type: String,
			required: false,
			default: '确定'
		},
		cancelBtnText: {
			type: String,
			required: false,
			default: '取消'
		}
	},
	methods: {
		onCancel() {
			this.$emit('cancel')
		},
		onConfirm() {
			this.$emit('confirm')
		},
		onBgClick(event) {
			event.stopPropagation()
		}
	}
}
</script>

<style scoped>
input {
	color: #FF8900;
}

.sl-confirm-modal {
	max-width: 90%;
	min-width: 60%;
	position: fixed;
	background-color: #1f1f35;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	border-radius: 0.4em;
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
	padding: 0.8em;
}

.swiper-slide-active > .sl-avatar-name {
	text-shadow: 2px 2px 5px red;
}

.sl-confirm-modal-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.sl-confirm-modal-title {
	font-size: 1.2em;
	width: 100%;
	text-align: left;
	color: #E6D5B8;
	margin-bottom: 0.8em;
}

.sl-confirm-send-vcode-btn {
	background-color: #ff8900;
	font-size: 1em;
	color: #3d3020;
	border-radius: 0.2em;
	padding: 0 0.3em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	word-break: keep-all;
}

.sl-confirm-send-vcode-btn:active {
	transform: scale(0.9);
}

.sl-confirm-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.2em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1 1;
}

input.sl-confirm-input:focus {
	border-bottom: 2px solid #FF8900;
}

input.sl-confirm-input-email:focus {
	border-bottom: 2px solid #FF8900;
}

.sl-confirm-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.sl-confirm-modal-bg {
	position: fixed;
	width: 100vw;
	/* backdrop-filter: blur(8px) opacity(100%) brightness(90%); */
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	left: 0;
	height: 100vh;
	backdrop-filter: blur(3px);
}

.sl-confirm-modal-close-container {
	width: 100%;
	text-align: right;
}

.sl-confirm-modal-bg-container{
	width: 100vw;
	height: 100vh;
}
.sl-confirm-modal-btn-container{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-sizing: border-box;
}
</style>