<template>
	<div class="statistic-visit-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="visit-statistic-label">本月新增用户数：{{ this.addThisMonthCount }}</div>
		<div class="visit-statistic-label">本月流失用户数：{{ this.lostThisMonthCount }}</div>
		<div class="chart-container">
			<canvas ref="userChartCanvas"></canvas>
		</div>
		<div class="chart-container">
			<canvas ref="memberChartCanvas"></canvas>
		</div>
		<div class="chart-container">
			<canvas ref="ratioChartCanvas"></canvas>
		</div>
	</div>
</template>
<script>
import {getRequestAsync, hideLoading, showLoading, visitClassifyByUrl} from "@/utils/methods";

import Chart from 'chart.js/auto'

export default {
	name: "UserStatisticView",
	components: {},
	data() {
		return {
			addThisMonthCount: 0,
			lostThisMonthCount: 0,
			chartData: [],

		}

	},
	async beforeMount() {
		await this.getUserStatisticInfo()
		await this.getMemberInfo()
	},
	methods: {
		async getUserStatisticInfo () {
			showLoading()
			const result = await getRequestAsync('admin/stats/getUserStatisticInfo')
			hideLoading()
			if (result?.code === 200) {
				this.addThisMonthCount = result.msg?.addThisMonthCount
				this.lostThisMonthCount = result.msg?.lostThisMonthCount
			}
		},
		async getMemberInfo () {
			showLoading()
			const result = await getRequestAsync('statistic/memberInfo')
			hideLoading()
			if (result?.code === 200) {
				this.chartData = result?.msg

				this.initUserChart()
				this.initMemberChart()
				this.initRatioChart()
			}
		},
		async onRefreshClicked() {
			await this.getUserStatisticInfo()
			await this.getMemberInfo()
		},
		initUserChart() {
			const ctx = this.$refs.userChartCanvas

			this.chart = new Chart(ctx, {
				type: 'line',
				data: {
					labels: this.chartData.map(item => item.month).reverse(), // 反转数组使日期从左到右升序
					datasets: [
						{
							label: '新注册用户数',
							data: this.chartData.map(item => item.newRegisterCount).reverse(),
							borderColor: '#4BC0C0',
							tension: 0.1
						},
						{
							label: '安装数',
							data: this.chartData.map(item => item.installCount).reverse(),
							borderColor: '#FFCE56',
							tension: 0.1
						}
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							position: 'top',
						},
						title: {
							display: true,
							text: '用户数据趋势图'
						}
					},
					scales: {
						y: {
							beginAtZero: true
						}
					}
				}
			})
		},
		initMemberChart() {
			const ctx = this.$refs.memberChartCanvas

			this.chart = new Chart(ctx, {
				type: 'line',
				data: {
					labels: this.chartData.map(item => item.month).reverse(), // 反转数组使日期从左到右升序
					datasets: [
						{
							label: '流失会员数',
							data: this.chartData.map(item => item.lostUserCount).reverse(),
							borderColor: '#FF6384',
							tension: 0.1
						},
						{
							label: '增量会员数',
							data: this.chartData.map(item => item.increaseUserCount).reverse(),
							borderColor: '#36A2EB',
							tension: 0.1
						}
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							position: 'top',
						},
						title: {
							display: true,
							text: '用户数据趋势图'
						}
					},
					scales: {
						y: {
							beginAtZero: true
						}
					}
				}
			})
		},
		initRatioChart() {
			const ctx = this.$refs.ratioChartCanvas

			this.chart = new Chart(ctx, {
				type: 'line',
				data: {
					labels: this.chartData.map(item => item.month).reverse(), // 反转数组使日期从左到右升序
					datasets: [
						{
							label: '注册用户/新增会员',
							data: this.chartData.map(item => item.regIncRate).reverse(),
							borderColor: '#FF6384',
							tension: 0.1
						},
						{
							label: '安装用户/注册用户',
							data: this.chartData.map(item => item.installRegRate).reverse(),
							borderColor: '#36A2EB',
							tension: 0.1
						}
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							position: 'top',
						},
						title: {
							display: true,
							text: '用户数据趋势图'
						}
					},
					scales: {
						y: {
							beginAtZero: true
						}
					}
				}
			})
		}
	}
}
</script>

<style scoped>
.statistic-refresh-btn-container{
	padding-bottom: 0.6em;
}
.visit-statistic-label{
	color: #FF8900;
}

.chart-container {
	width: 100%;
	height: 400px;
	position: relative;
}

</style>