<template>
	<div class="statistic-visit-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="visit-statistic-label">今日荐注册数：{{this.todayReferRegisterCount}}</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">推荐码</div>
				<div class="statistic-visit-sub-container-cell">数量</div>
			</div>

			<template v-for="item in this.todayRegisterCountClassifiedByReferCode" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.referCode }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>
		<div class="visit-statistic-label">昨日荐注册数：{{this.yesterdayReferRegisterCount}}</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">推荐码</div>
				<div class="statistic-visit-sub-container-cell">数量</div>
			</div>

			<template v-for="item in this.yesterdayRegisterCountClassifiedByReferCode" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.referCode }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>
		<div class="visit-statistic-label">本月荐注册数：{{this.thisMonthReferRegisterCount}}</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">推荐码</div>
				<div class="statistic-visit-sub-container-cell">数量</div>
			</div>

			<template v-for="item in this.thisMonthRegisterCountClassifiedByReferCode" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.referCode }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
				</div>
			</template>
		</div>
	</div>
</template>
<script>
import {
	getRequestAsync,
	hideLoading,
	registerListClassifyByReferCode,
	showLoading,
	visitClassifyByUrl
} from "@/utils/methods";

export default {
	name: "RegisterStatisticView",
	components: {},
	data() {
		return {
			todayReferRegisterCount: 0,
			todayRegisterCountClassifiedByReferCode: [],
			yesterdayReferRegisterCount: 0,
			yesterdayRegisterCountClassifiedByReferCode: [],
			thisMonthReferRegisterCount: 0,
			thisMonthRegisterCountClassifiedByReferCode: []
		}
	},
	async beforeMount() {
		await this.getRegisterInfo()
	},
	methods: {
		async getRegisterInfo (){
			showLoading()
			const result = await getRequestAsync('admin/stats/getRegisterInfo')
			hideLoading()
			if (result?.code === 200) {
				this.todayReferRegisterCount = result.msg?.todayReferenceRecords?.length
				this.todayRegisterCountClassifiedByReferCode = registerListClassifyByReferCode(result.msg?.todayReferenceRecords)
				this.yesterdayReferRegisterCount = result.msg?.yesterdayReferenceRecords?.length
				this.yesterdayRegisterCountClassifiedByReferCode = registerListClassifyByReferCode(result.msg?.yesterdayReferenceRecords)
				this.thisMonthReferRegisterCount = result.msg?.thisMonthReferenceRecords?.length
				this.thisMonthRegisterCountClassifiedByReferCode = registerListClassifyByReferCode(result.msg?.thisMonthReferenceRecords)
			}
		},
		async onRefreshClicked() {

			await this.getRegisterInfo()
		}
	}
}
</script>

<style scoped>


input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-visit-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container{
	display: table;
	align-items: start;
	color: #E6D5B8;
	padding-bottom: 0.2em;
}

.statistic-visit-sub-container-row{
	display: table-row;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container-cell{
	display: table-cell;
	align-items: start;
	color: #E6D5B8;
	text-align: start;
	padding-right: 0.8em;
}

</style>