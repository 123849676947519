<template>
	<div class="statistic-visit-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="visit-statistic-label">今日推荐付费总笔数：{{this.paymentRecordsCountToday}}</div>
		<div class="visit-statistic-label">今日推荐付费总金额：{{this.paymentRecordsAmountToday}}</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">推荐码</div>
				<div class="statistic-visit-sub-container-cell">数量</div>
				<div class="statistic-visit-sub-container-cell">总金额</div>
			</div>

			<template v-for="item in this.paymentRecordsToday" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.referCode }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.amount }}</div>
				</div>
			</template>
		</div>
		<div class="visit-statistic-label">本月推荐付费总笔数：{{this.paymentRecordsCountThisMonth}}</div>
		<div class="visit-statistic-label">本月推荐付费总金额：{{this.paymentRecordsAmountThisMonth}}</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">推荐码</div>
				<div class="statistic-visit-sub-container-cell">数量</div>
				<div class="statistic-visit-sub-container-cell">总金额</div>
			</div>

			<template v-for="item in this.paymentRecordsThisMonth" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.referCode }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.amount }}</div>
				</div>
			</template>
		</div>
		<div class="visit-statistic-label">总推荐付费总笔数：{{this.allPaymentRecordsCount}}</div>
		<div class="visit-statistic-label">总推荐付费总金额：{{this.allPaymentRecordsAmount}}</div>
		<div class="statistic-visit-sub-container">
			<div class="statistic-visit-sub-container-row">
				<div class="statistic-visit-sub-container-cell">推荐码</div>
				<div class="statistic-visit-sub-container-cell">数量</div>
				<div class="statistic-visit-sub-container-cell">总金额</div>
			</div>

			<template v-for="item in this.allPaymentRecords" :key="item.tag">

				<div class="statistic-visit-sub-container-row">
					<div class="statistic-visit-sub-container-cell">{{ item.referCode }}</div>
					<div class="statistic-visit-sub-container-cell">{{ item.count }}</div>
					<div class="statistic-visit-sub-container-cell">{{ Math.round(item.amount) }}</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script >
import {getRequestAsync, hideLoading, showLoading, paymentRecordsClassifiedByReferCode} from "@/utils/methods";

export default {
	name: "PaymentStatisticView",
	components: {},
	async mounted() {
		if (this.paymentRecordsThisMonth.length === 0) {
			await this.getPaymentStatistic()
		}
	},
	data() {
		return {
			paymentRecordsToday: [],
			paymentRecordsCountToday: 0,
			paymentRecordsAmountToday: 0,
			paymentRecordsThisMonth: [],
			paymentRecordsCountThisMonth: 0,
			paymentRecordsAmountThisMonth: 0,
			allPaymentRecords: [],
			allPaymentRecordsCount: 0,
			allPaymentRecordsAmount: 0
		}
	},
	methods: {
		async getPaymentStatistic () {
			showLoading()
			const result = await getRequestAsync('admin/stats/getPaymentStatisticInfo')
			hideLoading()
			if (result?.code === 200) {
				this.paymentRecordsToday = paymentRecordsClassifiedByReferCode(result.msg?.paymentRecordsToday)
				this.paymentRecordsCountToday = result.msg?.paymentRecordsToday?.length
				this.paymentRecordsAmountToday = 0
				for (const item of result.msg?.paymentRecordsToday) {
					this.paymentRecordsAmountToday += item.amount / 100
				}
				this.paymentRecordsThisMonth = paymentRecordsClassifiedByReferCode(result.msg?.paymentRecordsThisMonth)
				this.paymentRecordsCountThisMonth = result.msg?.paymentRecordsThisMonth?.length
				this.paymentRecordsAmountThisMonth = 0
				for (const item of result.msg?.paymentRecordsThisMonth) {
					this.paymentRecordsAmountThisMonth += item.amount / 100
				}
				this.allPaymentRecordsCount = result.msg?.allReferenceRecords?.length
				this.allPaymentRecordsAmount = 0

				for (const item of result.msg?.allReferenceRecords) {
					this.allPaymentRecordsAmount += parseFloat(item.amount)
				}
				this.allPaymentRecordsAmount = Math.round(this.allPaymentRecordsAmount / 100)
				this.allPaymentRecords = paymentRecordsClassifiedByReferCode(result.msg?.allReferenceRecords)
			}
		},
		async onRefreshClicked() {
			this.getPaymentStatistic()
		}
	}
}
</script>
<style scoped>

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-visit-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container{
	display: table;
	align-items: start;
	color: #E6D5B8;
	padding-bottom: 0.2em;
}

.statistic-visit-sub-container-row{
	display: table-row;
	align-items: start;
	color: #E6D5B8;
}

.statistic-visit-sub-container-cell{
	display: table-cell;
	align-items: start;
	color: #E6D5B8;
	text-align: start;
	padding-right: 0.8em;
}

</style>