<template>
	<div class="health-check-container">
		<div class="refresh-btn" @click="onRefresh">刷新</div>
		<div class="health-check-label-header">项目一</div>
		<div class="health-check-label">更新时间：{{ this.revReport?.createTimeString }}</div>
		<div class="health-check-label">测试节点：{{ this.revReport?.testerLabel }}</div>
		<div class="health-check-label">测试结果：</div>
		<template v-for="item in this.revReport?.result" :key="item.value?.url">
			<div class="test-result-item" :style="this.getTestResultStyle(item)">{{ item.value?.url }},
				code: {{ item.value?.statusCode }}, tag: {{item.value?.tag}}
			</div>
		</template>
		<div class="health-check-label-header">项目二</div>
		<div class="health-check-label">更新时间：{{ this.airportReport?.createTimeString }}</div>
		<div class="health-check-label">测试节点：{{ this.airportReport?.testerLabel }}</div>
		<div class="health-check-label">测试结果：</div>
		<template v-for="item in this.airportReport?.result" :key="item.value?.url">
			<div class="test-result-item" :style="this.getTestResultStyle(item)">{{ item.value?.url }},
				{{ item.value?.statusCode }}
			</div>
		</template>
		<div class="health-check-label-header">文件服务器</div>
		<div class="health-check-label">更新时间：{{ this.fileServerReport?.createTimeString }}</div>
		<div class="health-check-label">测试节点：{{ this.fileServerReport?.testerLabel }}</div>
		<div class="health-check-label">测试结果：</div>
		<template v-for="item in this.fileServerReport?.result" :key="item.value?.url">
			<div class="test-result-item" :style="this.getTestResultStyle(item)">{{ item.value?.url }},
				{{ item.value?.statusCode }}
			</div>
		</template>
	</div>
</template>

<script>

import {getRequestAsync, hideLoading, postRequestAsync, showLoading} from "@/utils/methods";

export default {
	name: "HealthCheckPage",
	components: {},
	data() {
		return {
			revReport: null,
			fileServerReport: null,
			airportReport: null
		}
	},
	async mounted() {
		this.getRevReports()
		this.getAirportReports()
		this.getFileServerReports()
	},
	methods: {
		async getRevReports() {
			showLoading()
			const result = await getRequestAsync('admin/stats/revTestReport')
			hideLoading()
			if (result?.code === 200 && result?.msg) {
				this.revReport = result?.msg
			}
		},
		async getFileServerReports() {
			showLoading()
			const result = await getRequestAsync('admin/stats/fileTestReport')
			hideLoading()
			if (result?.code === 200 && result?.msg) {
				this.fileServerReport = result?.msg
			}
		},
		async getAirportReports() {
			showLoading()
			const result = await getRequestAsync('admin/stats/airportTestReport')
			hideLoading()
			if (result?.code === 200 && result?.msg) {
				this.airportReport = result?.msg
			}
		},
		getTestResultStyle(item) {
			if (item.value?.statusCode === 200) {
				return {
					'color': 'green'
				}
			} else {
				return {
					'color': 'red'
				}
			}

		},
		onRefresh() {
			this.getRevReports()
			this.getAirportReports()
		}
	}
}
</script>

<style scoped>
.health-check-container {
	background-color: #191123;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 0.4em;
	box-sizing: border-box;
	align-items: start;
}

.health-check-label {

	color: #E6D5B8;
	font-size: 1em;
}

.health-check-label-header{
	color: #E6D5B8;
	font-size: 1em;
	font-weight: bold;
	margin-top: 0.4em;
}

.test-result-item {
	width: 100%;
	box-sizing: border-box;
	font-size: 1em;
	text-align: start;
	word-break: break-all;
}

.refresh-btn {
	color: #E6D5B8;
	border: 1px solid #E6D5B8;
	padding: 0.2em 0.4em;
	font-size: 1.2em;
	line-height: 1em;
	height: fit-content;
	border-radius: 0.2em;
	cursor: pointer;
	margin-bottom: 0.4em;
}

</style>