import {createApp} from 'vue'
import App from './App.vue'

import router from './router';
import {getServerUrl,
	verifyFp} from '@/utils/methods'
import Antd from 'ant-design-vue';
import store from '@/utils/store';

const app = createApp(App);
router.beforeEach(async (to, from, next) => {
	await getServerUrl()
	if (to.name === 'dashboard' || to.name === 'cs') {
		if (await verifyFp() === true) {
			next()
		} else {
			next({name: 'login'})
		}
		return
	}
	next()

});

app.use(router); // 使用Vue Router插件
app.use(Antd)
app.use(store)
app.mount('#app')
