<template>
	<div class="login-page-main-container">
		<transition name="fade">
			<login-modal @onNewUser="this.onRegisterNewUser" @onForgotPassword="this.onForgotPassword" @onLoginSuccess="this.onLoginSuccess"></login-modal>
		</transition>
	</div>
</template>

<script>

import LoginModal from '../components/LoginModal.vue'

export default {
	name: "LoginPage",
	components: {
		LoginModal
	},
	data() {
		return {
		}
	},
	async mounted() {
	},
	methods: {
		onRegisterNewUser() {
			this.currentDisplay = 'REGISTER'
		},
		onForgotPassword() {
			this.currentDisplay = 'RESETPASSWORD'
		},
		onLoginSuccess() {
			this.$router.push('/dashboard')
		}
	}
}
</script>

<style scoped>
.login-page-main-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-image: url('../images/matrix_bg.jpg');
	top: 0;
	left: 0;
}

</style>