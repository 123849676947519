<template>
	<div class="health-check-container">
		<div class="refresh-btn-container" @click="this.runCheck">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="health-check-label">list</div>
		<template v-for="item in this.listUrlsTestResult" :key="item.url">
			<div class="health-check-result-row">
				<div class="url-connect-success" v-if="item.response.ok === true"></div>
				<div class="url-connect-fail" v-if="item.response.ok !== true"></div>
				<div class="health-check-result-url-cell">{{ item.url }}</div>
			</div>
		</template>
		<div class="health-check-label">rev</div>
		<template v-for="item in this.revTestResult" :key="item.url">
			<div class="health-check-result-row">
				<div class="url-connect-success" v-if="item.response?.ok === true"></div>
				<div class="url-connect-fail" v-if="item.response?.ok !== true"></div>
				<div class="health-check-result-url-cell">{{ item.url }}</div>
			</div>
		</template>
	</div>
</template>

<script>

import {
	getRequestAsync,
	hideLoading,
	showLoading,
	fetchWithTimeout
} from "@/utils/methods";

export default {
	name: "HealthCheckView",
	components: {},
	data() {
		return {
			listUrls: [
				'server0.grcymnpgpwmhtfjfeelz.work',
				'server0.nebulabinaryproxy.win',
				'server0.czzhkractqqakfzdmrgd.cc',
				'server0.khljbzxpeytzgsqgtfki.cc',
				'server1.grcymnpgpwmhtfjfeelz.work',
				'server1.nebulabinaryproxy.win',
				'server1.czzhkractqqakfzdmrgd.cc',
				'server1.khljbzxpeytzgsqgtfki.cc',
			],
			listUrlsTestResult: [],
			ipList: [],
			revTestResult: []
		}
	},
	async mounted() {
		await this.runCheck()
	},
	methods: {
		async runCheck() {
			showLoading()
			const results = []
			const requests = this.listUrls.map(async item => {
				await this.checkListConnection(item, results)
			})
			await Promise.all(requests)
			for (const item of results) {
				console.log(`url: ${item.url}`)
				console.log(`response.ok: ${item.response.ok}`)
			}
			this.listUrlsTestResult = results

			const revResults = []
			if (this.ipList.length > 0) {
				const revRequests = this.ipList.map(async item => {
					await this.checkRevConnection(item, revResults)
				})

				await Promise.all(revRequests)
				this.revTestResult = revResults
			}
			hideLoading()
		},
		async checkListConnection(url, results) {
			const myHeaders = new Headers();
			const requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			const response = await fetchWithTimeout(`https://${url}`, requestOptions)
			if (this.ipList.length === 0) {
				const decodedResponse = atob(await response.text());
				const tmpList = JSON.parse(decodedResponse)
				const tmp = []
				for (const item of tmpList) {
					const tmpUrl = atob(item)
					tmp.push(tmpUrl)
				}
				this.ipList = tmp
			}
			results.push({response, url})
		},
		async checkRevConnection(url, results) {
			try {
				const myHeaders = new Headers();
				const requestOptions = {
					method: 'GET',
					headers: myHeaders,
					redirect: 'follow'
				};
				const response = await fetchWithTimeout(`${url}/web/test`, requestOptions)
				results.push({response, url})
			} catch (e) {
				results.push({response: null, url})
			}

		}
	}
}
</script>

<style scoped>
.health-check-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.health-check-label {
	color: #FF8900;
	font-size: 1.2em;
	text-align: start;
}

.health-check-result-row {
	color: #E6D5B8;
	font-size: 1em;
	text-align: start;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.health-check-result-url-cell {

}

.url-connect-success {
	width: 0.8em;
	height: 0.8em;
	background-color: green;
	border-radius: 0.4em;
	margin-right: 0.2em;
}

.url-connect-fail {
	width: 0.8em;
	height: 0.8em;
	background-color: red;
	border-radius: 0.4em;
	margin-right: 0.2em;
}

.refresh-btn-container {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}
</style>