<template>

	<div class="statistic-info-general-container">
		<div class="statistic-refresh-btn-container" @click="this.onRefreshClicked">
			<img src="../../../images/refresh.svg" alt="refresh" class="statistic-refresh-btn-img">
			<div class="statistic-refresh-btn-text">刷新</div>
		</div>
		<div class="statistic-info-container">
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日注册</div>
				<div class="stat-content">{{ this.userIncreaseToday }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日购买数</div>
				<div class="stat-content">{{ this.todayPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">今日购买金额</div>
				<div class="stat-content">￥{{ this.toadyPaymentAmount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">当前活跃用户数</div>
				<div class="stat-content">{{ this.currentActiveUser }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月注册用户</div>
				<div class="stat-content">{{ this.monthRegister }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月购买数</div>
				<div class="stat-content">{{ this.monthPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">本月购买金额</div>
				<div class="stat-content">{{ this.monthPaymentAmount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月注册用户</div>
				<div class="stat-content">{{ this.lastMonthRegister }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月购买数</div>
				<div class="stat-content">{{ this.lastMonthPaymentCount }}</div>
			</div>
			<div class="stat-grid-cell">
				<div class="stat-cell-title">上月购买金额</div>
				<div class="stat-content">{{ this.lastMonthPaymentAmount }}</div>
			</div>
		</div>
	</div>
</template>
<script>

import {getRequestAsync, hideLoading, showLoading} from "@/utils/methods";

export default{
	name: "GeneralAirportStatisticView",
	components: {
		// UserStatisticView,
		// PaymentStatisticView,
		// GeneralStatisticView,
		// DownloadStatisticView,
		// TrafficStatisticView,
		// RegisterStatisticView
	},
	data() {
		return {
			userIncreaseToday: 0,
			todayPaymentCount: 0,
			toadyPaymentAmount: 0,
			currentActiveUser: 0,
			monthPaymentCount: 0,
			lastMonthPaymentCount: 0,
			monthPaymentAmount: 0,
			lastMonthPaymentAmount: 0,
			monthRegister: 0,
			lastMonthRegister: 0
		}
	},
	async beforeMount() {
		await this.getStatisticInfo()
	},
	methods: {
		async getStatisticInfo() {
			showLoading()
			const result = await getRequestAsync('admin/stats/getAirportStatisticInfo')
			hideLoading()
			if (result?.code === 200) {
				this.userIncreaseToday = result.msg.userIncreaseToday
				this.todayPaymentCount = result.msg.todayPaymentCount
				this.toadyPaymentAmount = result.msg.toadyPaymentAmount
				this.currentActiveUser = result.msg.currentActiveUser
				this.monthPaymentCount = result.msg.monthPaymentCount
				this.lastMonthPaymentCount = result.msg.lastMonthPaymentCount
				this.monthPaymentAmount = result.msg.monthPaymentAmount
				this.lastMonthPaymentAmount = result.msg.lastMonthPaymentAmount
				this.monthRegister = result.msg.monthRegister
				this.lastMonthRegister = result.msg.lastMonthRegister
			}
		},
		async onRefreshClicked() {
			await this.getStatisticInfo()
		}
	}
}
</script>

<style scoped>

.statistic-refresh-btn-container{
	padding-bottom: 0.6em;
}
.statistic-info-general-container{
	width: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: start;
	color: #E6D5B8;
}

input.cs-input:focus {
	border-bottom: 2px solid #FF8900;
}

.statistic-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 列宽度设置为相等的三列 */
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
	width: 100%;
	box-sizing: border-box;
}

.stat-grid-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(230, 213, 184, 0.2);
}

.stat-cell-title {
	padding-top: 0.4em;
	color: var(--white-color);
}

.stat-content {
	font-size: 1.4em;
	color: var(--white-color);
}

@media only screen and (max-width: 900px) {
	.statistic-info-container {
		grid-template-columns: 1fr 1fr;
	}
}
</style>