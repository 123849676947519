<template>
	<div class="general-modal-bg">
		<div class='general-modal-container'>
			<div class='general-modal-header-container'>
				<div>错误详情</div>
				<img class="general-modal-header-close-btn" src="../images/close_white.svg" alt="close"
				     @click="this.onClose">
			</div>
			<div class="error-detail-container">
				<div class='error-os-label'>OS: </div>
				<div class='error-os'>{{ this.errorItem?.os }}</div>
				<div class='error-os-label'>版本号: </div>
				<div class='error-os'>{{ this.errorItem?.appInfo }}</div>
				<div class='error-os-label'>设备ID: </div>
				<div class='error-os'>{{ this.errorItem?.deviceId }}</div>
				<div class='error-os-label'>报告时间: </div>
				<div class='error-os'>{{ this.errorItem?.reportTimeString }}</div>
				<div class='error-os-label'>错误类型: </div>
				<div class='error-os'>{{ this.errorItem?.type }}</div>
				<div class='error-os-label'>错误原因: </div>
				<div class='error-os'>{{ this.errorItem?.reason }}</div>
				<div class='error-os-label'>设备信息：</div>
				<div class='error-os'>{{ this.errorItem?.deviceInfo }}</div>
				<div class='error-os-label'>Stack Trace: </div>
				<div class='error-os'>{{ this.errorItem?.stackTrace }}</div>
			</div>
		</div>

	</div>
</template>
<script>

import ImageDisplayModal from "@/components/ImageDisplayModal.vue";
import {hideLoading, postRequestAsync, showLoading} from "@/utils/methods";
import moment from "moment-timezone";

export default {
	name: "ErrorDetailModal",
	data() {
		return {
			conversationList: [],
			viewUserDetail: false,
			userDetail: null,
			toSendMsg: '',
			viewTicketType: false,
			viewDeviceInfo: false,
			deviceInfo: [],
			displayImgUrl: null
		}
	},
	props: {
		errorItem: {
			type: Object,
			required: true,
			toSendMsg: ''
		}
	},
	async mounted() {
	},
	methods: {
		onClose() {
			this.$emit('close');
		}
	}
}
</script>
<style scoped>
.error-detail-container {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	flex: 1;
}

.error-os {
	width: 100%;
	font-size: 1.2em;
	color: #E6D5B8;
	text-align: start;
	word-break: break-all;
	white-space: pre;
	margin-bottom: 0.8em;
}
.error-os-label{
	width: 100%;
	font-size: 1.2em;
	color: rgba(230, 213, 184, 0.6);
	text-align: start;
	word-break: break-all;
	white-space: pre;
}
</style>