<template>
	<div class="report-page-container">
		<ErrorDetailModal v-if="showErrorDetailModal === true" @close="onModalClose"
		                  :errorItem="this.selectedItem"></ErrorDetailModal>
		<div class="cs-view-switch-container">
			<div class="cs-view-switch-wrapper">
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'crash_report'}"
				     @click="this.onViewSwitcherClicked('crash_report')">崩溃报告
				</div>
				<div class="cs-view-switch-btn" :class="{'active': this.currentViewSwitcher === 'error_report'}"
				     @click="this.onViewSwitcherClicked('error_report')">错误报告
				</div>
			</div>
		</div>

		<div class="cs-main-container">
			<div class="btn-container">
				<a-pagination v-model:current="this.page" simple v-model:pageSize="this.pageSize"
				              :total="this.totalCount" @change="onErrorReportPageChanged" style="padding: 0.8em 0;"
				              :showSizeChanger="false"/>

				<div class="header-btn" @click="onRefreshBtnClicked">刷新</div>
			</div>

			<div class="list-container">
				<template v-for="item in this.errorList" :key="item.uuid">
					<div class="item-container" @click="onItemClick(item)">

						<div class="item-title" style="margin-bottom: 0.4em;">
							os：{{ item.os }}
						</div>
						<div class="item-title" style="margin-bottom: 0.4em;">
							版本号：{{ item.appInfo }}
						</div>
						<div class="item-title" style="margin-bottom: 0.4em;">
							设备ID：{{ item.deviceId }}
						</div>
						<div class="item-title" style="margin-bottom: 0.4em;">
							错误类型：{{ item.type }}
						</div>
						<div class="item-time">
							报告时间：{{ item.reportTimeString }}
						</div>
					</div>
				</template>

			</div>
		</div>
	</div>
</template>
<script>

import {hideLoading, postRequestAsync, showLoading} from "@/utils/methods";
import ErrorDetailModal from "@/components/ErrorDetailModal.vue";

export default {
	name: "ReportPage",
	components: {ErrorDetailModal},
	data() {
		return {
			currentViewSwitcher: 'crash_report',
			page: 1,
			totalCount: 0,
			pageSize: 20,
			errorList: [],
			showErrorDetailModal: false,
			selectedItem: null
		}
	},
	async mounted() {
		await this.getCrashReport()
	},
	methods: {
		onViewSwitcherClicked(reportType) {
			if (this.currentViewSwitcher !== reportType) {
				if (reportType === 'crash_report') {
          this.page = 1
					this.getCrashReport()
				} else {
          this.page = 1
					this.getErrorReport()
				}
			}
			this.currentViewSwitcher = reportType
		},
		onRefreshBtnClicked() {
			if (this.currentViewSwitcher === 'crash_report') {
				this.getCrashReport()
			} else {
				this.getErrorReport()
			}
		},
		onErrorReportPageChanged(data) {
			this.page = data
			if (this.currentViewSwitcher === 'crash_report') {
				this.getCrashReport()
			} else {
				this.getErrorReport()
			}
		},
		async getCrashReport() {
			showLoading()
			const result = await postRequestAsync('admin/report/getCrashReport', {
				page: this.page
			})
			hideLoading()

			if (result.code === 200) {
				this.$message.success('获取成功');
				this.errorList = result.msg?.records
				this.totalCount = result.msg?.count
			}
		},
		async getErrorReport () {
			showLoading()
			const result = await postRequestAsync('admin/report/getErrorReport', {
				page: this.page
			})
			hideLoading()

			if (result.code === 200) {
				this.$message.success('获取成功');
				this.errorList = result.msg?.records
				this.totalCount = result.msg?.count
			}
		},
		onItemClick(item) {
			this.selectedItem = item
			this.showErrorDetailModal = true
		},
		onModalClose () {
			this.showErrorDetailModal = false
		}
	}
}
</script>

<style scoped>
.report-page-container {

	min-height: 100vh;
	position: relative;
	background-color: #191123;
	overflow-x: hidden;
	width: 100vw;

	padding: 1em 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
</style>