<template>
	<div class="statistic-view-main-container">
		<a-tabs v-model:activeKey="mainTabKey">
			<a-tab-pane key="general" tab="概要">
				<GeneralStatisticView></GeneralStatisticView>


			</a-tab-pane>
			<a-tab-pane key="browse" tab="访问统计" force-render>
				<TrafficStatisticView></TrafficStatisticView>
			</a-tab-pane>
			<a-tab-pane key="download" tab="下载统计">
				<DownloadStatisticView></DownloadStatisticView>
			</a-tab-pane>
			<a-tab-pane key="register" tab="注册统计">
				<RegisterStatisticView></RegisterStatisticView>
			</a-tab-pane>
			<a-tab-pane key="users" tab="用户统计">
				<UserStatisticView></UserStatisticView>
			</a-tab-pane>
			<a-tab-pane key="payment" tab="付费统计">
				<PaymentStatisticView></PaymentStatisticView>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>

import {defineComponent} from "vue";
import RegisterStatisticView from "@/pages/statistic/vpn/RegisterStatisticView.vue";
import TrafficStatisticView from "@/pages/statistic/vpn/TrafficStatisticView.vue";
import DownloadStatisticView from "@/pages/statistic/vpn/DownloadStatisticView.vue";
import GeneralStatisticView from "@/pages/statistic/vpn/GeneralStatisticView.vue";
import PaymentStatisticView from "@/pages/statistic/vpn/PaymentStatisticView.vue";
import UserStatisticView from "@/pages/statistic/vpn/UserStatisticView.vue";

export default{
	name: "VpnStatisticView",
	components: {
		UserStatisticView,
		PaymentStatisticView,
		GeneralStatisticView,
		DownloadStatisticView,
		TrafficStatisticView,
		RegisterStatisticView
	},
	data() {
		return {
			mainTabKey: 'general'
		}
	}
}
</script>
<style scoped>
.statistic-view-main-container{
	width: 100%;
	box-sizing: border-box;
}
</style>